@use '@shared/util-styling/mixins';

.profile-dropdown-summary {
  padding: 0 var(--spacing-16) var(--spacing-12);

  &__photo {
    align-items: center;
    background-color: var(--mdrt-color-backgrounds-bg-surface-03);
    border-radius: var(--mdrt-corner-radius-maximum);
    display: flex;
    height: 112px;
    justify-content: center;
    margin-bottom: var(--spacing-12);
    overflow: hidden;
    width: 112px;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__icon {
    color: var(--mdrt-color-text-on-light-placeholder);
    height: 32px;
  }

  &__name {
    color: var(--mdrt-color-text-on-light-primary);
    word-break: break-word;
  }

  &__id {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-title-4);
  }

  &__chip {
    margin-left: calc(var(--spacing-8) * -1);
    padding-top: var(--spacing-12);
  }

  @include mixins.breakpoint('medium') {
    border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
    margin-bottom: var(--spacing-8);
    padding: var(--spacing-8);

    &__name {
      font: var(--mdrt-title-3);
    }

    &__photo {
      height: 40px;
      margin-bottom: var(--spacing-8);
      width: 40px;
    }

    &__icon {
      height: 16px;
    }
  }

  @include mixins.breakpoint('medium', max) {
    &__name {
      font: var(--mdrt-title-2);
    }

    &__inner {
      border-bottom: 1px solid var(--mdrt-color-borders-dark-02);
      padding: var(--spacing-48) var(--spacing-8) var(--spacing-20);
    }
  }
}
