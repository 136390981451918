@use 'sass:map';
@use '../tokens' as vars;

@mixin breakpoint($size, $dir: min) {
  $breakpoints: (
    'small': (
      'min-width': vars.$breakpoint-size-s,
      'max-width': vars.$breakpoint-size-s - 1,
    ),
    'medium': (
      'min-width': vars.$breakpoint-size-m,
      'max-width': vars.$breakpoint-size-m - 1,
    ),
    'large': (
      'min-width': vars.$breakpoint-size-l,
      'max-width': vars.$breakpoint-size-l - 1,
    ),
    'xlarge': (
      'min-width': vars.$breakpoint-size-xl,
      'max-width': vars.$breakpoint-size-xl - 1,
    ),
  );
  $query: map.get($breakpoints, $size);

  @media (#{$dir}-width: #{map.get($query, '#{$dir}-width')}) {
    @content;
  }
}

@mixin device($type) {
  $devices: (
    // Smartphones, touchscreens
    'touch':
      (
        'hover': 'none',
        'pointer': 'coarse',
      ),
    // Desktops, laptops, touchpads
    'pointer':
      (
        'hover': 'hover',
        'pointer': 'fine',
      )
  );
  $device: map.get($devices, $type);

  @media (hover: #{map.get($device, 'hover')}) and (pointer: #{map.get($device, 'pointer')}) {
    @content;
  }
}
