@mixin button-reset {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
  text-align: inherit;
}
