@use '../mixins/link-reset' as *;
@use '../mixins/button-reset' as *;
@use '../mixins/responsive' as responsive;

@mixin menu-item($is-button: false) {
  @if $is-button == true {
    @include button-reset;
  } @else {
    @include link-reset;
  }

  align-items: center;
  border-radius: var(--mdrt-corner-radius-s);
  display: flex;
  font: var(--mdrt-title-3);
  gap: var(--spacing-12);
  padding: var(--spacing-12);
  transition: all 0.2s ease-in-out;
  width: 100%;

  @include responsive.breakpoint('medium', max) {
    color: var(--mdrt-color-text-on-light-primary);
  }

  @include responsive.breakpoint('medium') {
    &:hover {
      background: var(--mdrt-color-backgrounds-bg-neutral-hover);
    }

    &:focus {
      background: var(--mdrt-color-backgrounds-bg-primary-selected);
    }
  }

  &__icon {
    pointer-events: none;
    width: 24px;
  }
}
