@use '@shared/util-styling/mixins';

.sub-menu {
  display: none;
  flex-direction: column;
  gap: var(--spacing-4);
  margin-block: var(--spacing-4);

  &--expanded {
    display: flex;
  }

  &__item {
    @include mixins.link-reset;

    color: var(--mdrt-color-text-on-light-secondary);
    display: flex;
    font: var(--mdrt-title-3);
    padding: var(--spacing-12) var(--spacing-12) var(--spacing-12) var(--spacing-32);
    word-wrap: break-word;
  }

  @include mixins.breakpoint('medium') {
    background-color: var(--mdrt-color-backgrounds-bg-surface);
    display: flex;
    flex-direction: row;
    gap: var(--spacing-20);
    height: var(--app-sub-menu-height);
    justify-content: center;
    left: 0;
    margin-block: 0;
    padding-block: var(--spacing-10) 6px;
    position: absolute;
    right: 0;
    top: 100%;

    &__item {
      color: var(--mdrt-color-backgrounds-bg-primary-fill-01);
      padding: 0 0 var(--spacing-2);
      text-decoration-color: transparent;
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 2px;
      text-underline-position: under;
      transition: all 0.2s ease-in-out;

      @include mixins.link-hover(2px);

      &--active {
        text-decoration-color: unset;
      }
    }
  }

  @include mixins.breakpoint('large') {
    gap: var(--spacing-40);
  }
}
