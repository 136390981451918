.impersonation-form {
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);

  &__actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__description {
    color: var(--mdrt-color-text-on-light-tertiary);
    font: var(--mdrt-body-4);
  }
}
