@use '@shared/util-styling/mixins';

.navigation-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.navigation {
  $_this: &;

  &--not-authenticated {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 80px;

    #{$_this}__list-secondary {
      margin-top: auto;
      padding: var(--spacing-16) var(--spacing-16) 0;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    padding: 0 var(--spacing-16);
  }

  &__list-secondary {
    padding: var(--spacing-12) var(--spacing-16) var(--spacing-24);

    li {
      &:first-child {
        border-top: 1px solid var(--mdrt-color-borders-dark-02);
        padding-top: var(--spacing-12);
      }
    }
  }

  &__authentication {
    padding: var(--spacing-16) var(--spacing-16) var(--spacing-24);

    &:empty {
      display: none;
    }
  }

  &__list-item-link {
    @include mixins.menu-item;

    &--expanded,
    &--expanded:hover {
      background: var(--mdrt-color-backgrounds-bg-primary-selected);
    }
  }

  &__list-item-icon {
    height: 24px;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
    width: 24px;
    will-change: transform;

    &--expanded {
      transform: rotate(90deg);
    }
  }

  @include mixins.breakpoint('medium') {
    &--not-authenticated {
      padding-top: 0;
    }

    &__list {
      align-items: center;
      flex-direction: row;
      gap: var(--spacing-20);
    }

    &__list-item-link {
      font: var(--mdrt-title-2);
      padding: 0;
      text-decoration-color: transparent;

      @include mixins.link-hover(2px);

      &--active {
        text-decoration-color: unset;
        text-decoration-line: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 2px;
        text-underline-position: under;
      }

      &:focus {
        background-color: transparent;
      }
    }
  }

  @include mixins.breakpoint('large') {
    &__list {
      gap: var(--spacing-48);
    }
  }
}
