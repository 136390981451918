@mixin link-hover($underline-offset: 0) {
  &:hover {
    background-color: transparent;
    text-decoration-color: var(--mdrt-color-backgrounds-bg-secondary-fill);
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: $underline-offset;
    text-underline-position: under;
  }
}
