@use '@shared/util-styling/mixins';

.profile-dropdown-item {
  @include mixins.button-reset;
  @include mixins.menu-item;

  &__with-divider {
    border-top: 1px solid var(--mdrt-color-borders-dark-02);

    @include mixins.breakpoint('medium') {
      margin-top: var(--spacing-8);
      padding-top: var(--spacing-8);
    }

    @include mixins.breakpoint('medium', max) {
      margin-top: var(--spacing-12);
      padding-top: var(--spacing-12);
    }
  }
}

.profile-dropdown-item-button {
  @include mixins.menu-item($is-button: true);
}
