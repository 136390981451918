@use '@shared/util-styling/mixins';

.authentication-button {
  @include mixins.menu-item($is-button: true);
}

.authentication-button-login {
  @include mixins.button-reset;
  @include mixins.link-hover;

  font: var(--mdrt-title-2);
}

.mobile-authentication-button {
  width: 100%;
}
