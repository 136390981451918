.loader-logo {
  height: 50px;
  left: 50%;
  position: absolute;
  top: calc(50% - 4px);
  transform: translate(-50%, -50%);
}

.loader-spinner {
  $_animation-time: 1.4s;
  $_size: 100px;

  animation: progressContainer $_animation-time linear infinite;
  color: var(--mdrt-color-text-on-light-action);
  display: inline-block;
  height: $_size;
  width: $_size;

  circle {
    animation: progressCircle $_animation-time ease-in-out infinite;
    stroke: currentcolor;
    stroke-dasharray: 80px, 200px;
    stroke-dashoffset: 0;
  }
}

@keyframes progressContainer {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes progressCircle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
}
