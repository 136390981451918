@use '@shared/util-styling/mixins';

.language-switcher {
  &__toggle-button {
    @include mixins.menu-item($is-button: true);

    @include mixins.breakpoint('medium') {
      @include mixins.button-reset;

      width: 40px;

      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }

  &__toggle-button-icon {
    display: none;

    &--mobile {
      height: 24px;
      width: 24px;
    }

    @include mixins.breakpoint('medium') {
      display: block;

      &--mobile {
        display: none;
      }
    }
  }

  &__toggle-button-text {
    @include mixins.breakpoint('medium') {
      display: none;
    }
  }

  &__actions-list {
    display: grid;
    gap: var(--spacing-12);
    grid-template-columns: 1fr;
    padding-block: var(--spacing-8);
    width: 100%;

    @include mixins.breakpoint('small') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__chip {
    &:global(.language-button) {
      display: flex;
      justify-content: center;
      width: 100%;

      @include mixins.breakpoint('medium') {
        min-width: 178px;
      }
    }
  }
}
