@use '@shared/util-styling/mixins';

.profile-dropdown {
  display: flex;

  &__trigger {
    @include mixins.button-reset;

    height: 40px;
    width: 40px;

    svg {
      // NOTE: leave pointer-events: none because google analytics needs to track <button /> tag attributes but not the <svg />
      pointer-events: none;
    }
  }

  &__content {
    min-width: 256px;
    padding: var(--spacing-8);
  }

  &__popover {
    display: none;

    @include mixins.breakpoint('medium') {
      display: block;
    }
  }
}
