@use '@shared/util-styling/mixins';

.header-content {
  $_this: &;

  display: none;

  &--secondary {
    #{$_this}__logo {
      color: var(--mdrt-color-text-on-dark-primary);
    }
  }

  @include mixins.breakpoint('medium') {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 26px;
    top: 0;
    width: 100%;

    &__logo {
      color: var(--mdrt-color-text-on-light-action);
      height: 57px;
      pointer-events: none;
      width: 228px;
    }

    &__controls {
      align-items: center;
      display: flex;
      gap: var(--spacing-8);
    }
  }

  @include mixins.breakpoint('large') {
    &__controls {
      gap: var(--spacing-16);
    }
  }
}
